<template>
  <div class="patentNews" v-loading="patentLoading | countLoading | barLoading | patentKeywordLoading">
    <div style="display: flex" v-if="!patentParams.company_id">
      <industry-bar :apiDataBarChart="apiDataBarChart"></industry-bar>
      <!-- <type-pie :apiDataPieChart="apiDataPieChart2" v-show="company_id_selected==0"></type-pie> -->
      <news-keyword-cloud style="padding-top:15px;width: 30%;margin-left:1%;" :apiDataLineChart="patentCloudData"></news-keyword-cloud>
    </div>
    <div class="title">
      <p id="zjxw">专利信息</p>
    </div>
    <div class="bombBox-content_v2 flexBox" style="padding-bottom: 20px" v-if="patentParams.company_id!=''">
      <patent-echarts :apiDataLineChart="patentEchartsData"></patent-echarts>
      <keyword-cloud :apiDataLineChart="wordCloudData"></keyword-cloud>
    </div>
    <patent-list :apiDataLineChart="patentListData"></patent-list>
      <!-- <div class="contentFooter">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="patentParams.page"
          :page-size="patentParams.page_size"
          :total="patentListTotal"
          @current-change="handlePageChangePatent"
        >
        </el-pagination>
      </div> -->
      <div class="showMore" @click="showMore">
          <p>{{ showMoreText }}</p>
        </div>
  </div>
</template>
<script>
import api from "@/api/api";
import cookie from "@/utils/common.js"
import { EventBus } from "../utils/eventBus.js";
import PatentEcharts from "../components/PatentEcharts.vue";
import KeywordCloud from "../components/KeywordCloud.vue";
import PatentList from "../components/PatentList.vue";
import IndustryBar from "../components/IndustryBar";
import NewsKeywordCloud from "../components/NewsKeywordCloud.vue"

export default {
  name: "PatentNews",
  components: {
    PatentEcharts,
    KeywordCloud,
    PatentList,
    IndustryBar,
    NewsKeywordCloud
  },
  data() {
    return {
      patentLoading: false,
      countLoading: false,
      barLoading: false,
      patentKeywordLoading: false,
      showMoreText: "加载更多",
      patentEchartsData: [],
      wordCloudData: {},
      patentCloudData: [],
      patentListData: [],
      patentListTotal: 0,
      apiDataBarChart: {
        legend: [],
        data: [],
      },
      patentParams: {
        page: 1,
        page_size: 10,
        company_id: "",
        permissions: ""
      },
    };
  },
  mounted() {
    // this.patentParams.company_id = sessionStorage.getItem("company_id")
   // this.patentParams.permissions = sessionStorage.getItem("permissions")
   cookie.setCookie("serverValue", "企业专利")
   this.patentParams.permissions = cookie.getCookie("permissions")
    if (this.patentParams.company_id) {
      this.getPatentCountAndKeyword(this.patentParams.company_id)
    }
    this.listenReset()
    this.getPatentList()
    this.getCompanyChoosed();
    this.getIndustryCount()
    this.getPatentKeyword()
  },
  beforeDestroy() {
    EventBus.$off("company_id");
    EventBus.$off("reset")
  },
  methods: {
    // 加载更多
    showMore() {
      this.patentParams.page += 1
      console.log("page", this.patentParams.page)
      if (this.patentParams.page <= 100) {
        this.getPatentList();
      } else {
        this.showMoreText = "已无更多"
      }
    },
    // 获取专利关键词
    getPatentKeyword() {
      this.patentKeywordLoading = true
      api.getPatentKeyword().then(res => {
        console.log("专利关键词", res)
        this.patentCloudData = res.data
        this.patentKeywordLoading = false
      })
    },
    // 获取行业构成分布
    getIndustryCount() {
      let params = {
        // permissions: sessionStorage.getItem("permissions"),
        permissions: cookie.getCookie("permissions")
      };
      this.barLoading = true
      this.apiDataBarChart.legend = [];
      this.apiDataBarChart.data = [];
      api.getIndustryCount(params).then((res) => {
        console.log("行业统计", res);
        // this.apiDataPieChart = res.data;
        res.data.forEach((i) => {
          let name = "";
          if (i.name.length > 4) {
            name = i.name.substring(0, 4) + "...";
          } else {
            name = i.name;
          }
          this.apiDataBarChart.legend.push(name);
          this.apiDataBarChart.data.push(i.value);
        });
        this.barLoading = false
      });
    },
    // 监听当前选择公司id
    getCompanyChoosed() {
      EventBus.$on("company_id", (data) => {
        console.log("company_id", data);
        this.patentListData = []
        this.patentParams.company_id = data;
        if (this.patentParams.company_id) {
          this.getPatentCountAndKeyword(data);
        }
        this.getPatentList();
        console.log("获取专利数据完成")
      });
    },
    // 获取专利统计和关键词云
    getPatentCountAndKeyword(company_id) {
      let params = {
        company_id: company_id,
      };
      this.countLoading = true;
      api.getCompanyBaseInfo(params).then((res) => {
        console.log("专利", res);
        this.patentEchartsData = res.data[0].year_count_list;
        this.wordCloudData = res.data[0].patent_keywords;
        this.countLoading = false;
      });
    },
    handlePageChangePatent(val) {
      console.log(val);
      this.patentParams.page = val;
      this.getPatentList();
    },
    // 监听重置
    listenReset() {
      EventBus.$on("reset", data => {
        this.patentParams.company_id = ""
        this.getPatentList();
        this.getIndustryCount()
        this.getPatentKeyword()
      })
    },
    // 获取专利列表
    getPatentList() {
      // let params = {
      //   company_id: company_id,
      //   page: 1,
      //   page_size: 10,
      // };
      this.patentLoading = true;
      api.getPatentList(this.patentParams).then((res) => {
        console.log("专利列表", res);
        // this.patentListData = res.data;
        this.patentListTotal = res.total;
        res.data.forEach(i => {
          this.patentListData.push(i)
        });
        this.patentLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.patentNews {
  // margin-top: 52px;
  width: 99.8%;
  // height: 20vw;
  margin-left: 5px;
  margin-top: 5px;
  background-color: white;
  border-radius: 6px;
  -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
  padding-bottom: 5px;
  // margin-bottom: 5px;
  // padding-top: 10px;
  .title {
    font-size: 17px;
  }
    .showMore {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    color: #333333;
    font-size: 14px;
    p {
      cursor: pointer;
      background-color: rgba(244,244,244,1);
      padding: 5px 40px 5px 40px;
      &:hover {
        background-color: rgb(228, 226, 226);
      }
    }
    
  }
}
</style>
